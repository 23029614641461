<template>
  <div class="zixun">
    <!-- 导航 -->

    <div class="mainWidth">
      <!-- 面包屑 -->
      <el-row type="flex" justify="center" class="crumbs">
        <el-col>
          <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }"
              >社科视频首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>资讯</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>

      <div class="container">
        <el-row type="flex" justify="center">
          <el-col>
            <!-- 副标题 -->
            <ViceHeadline title="资讯" />

            <el-row :gutter="$store.state.isEquipment == 'pc' ? 20 : 0">
              <el-col
                :xs="24"
                :sm="8"
                :md="8"
                :lg="8"
                :xl="8"
                v-for="item in list"
                :key="item.index"
              >
                <router-link
                  target="_blank"
                  :to="{
                    path: '/zixunDetails',
                    query: {
                      // key: item.KEYWORDS,
                      qing: item.id,
                      // xiang: item.doclibId,
                      // xiangdoc: item.docId,
                      mian: 'navzi',
                    },
                  }"
                >
                  <div class="card">
                    <div class="coverWrap">
                      <img v-lazy="item.pub_cover" alt="" class="cover" />
                      <div class="duration">
                        <p>{{ item.TIME_LENGTH }}</p>
                      </div>
                      <img
                        src="@/assets/image/zixun/播放.png"
                        alt=""
                        class="pause"
                      />
                    </div>
                    <div class="card_content">
                      <div class="title">{{ item.SYS_TOPIC }}</div>
                      <p class="time">
                        {{ $ModuleDate(item.RECORD_TIME) }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </el-col>
            </el-row>
            <Paging :value="value" @transfer="getData" />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import ViceHeadline from "@/components/ListHeadline.vue";
import NavType from "@/components/NavType.vue";
import Paging from "@/components/Paging.vue";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    ViceHeadline,
    NavType,
    Paging,
  },
  data() {
    return {
      list: [],
      value: {
        path: "45",
        amount: "9",
      },
    };
  },

  methods: {
    Details_btn(item) {
      this.$store.commit("setDetails", item);
      let routerJump = this.$router.resolve({
        path: "/zixunDetails",
      });
      window.open(routerJump.href, "_blank");
    },

    getData(msg) {
      this.list = msg;
    },
  },
};
</script>
<style scoped lang="scss">
h3 {
  margin: 2.5rem 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 0.625rem;
}

a {
  color: #42b983;
}

.zixun {
  background: #f9f9f9;

  .container {
    .card {
      margin-top: 1.5rem;
      // margin-bottom: 1rem;
      background: #ffffff;
      box-shadow: 0px 0px 13px 0px rgba(183, 183, 183, 0.38);
      position: relative;
      cursor: pointer;

      .cover {
        width: 100%;
        aspect-ratio: 4/3;
        display: block;
        height: 340px;
      }

      .duration {
        position: absolute;
        bottom: 0;
        right: 0rem;

        p {
          font-size: 1rem;
          font-weight: 400;
          color: #ffffff;
          line-height: 3rem;
          text-align: right;
          padding-right: 1.25rem;
          white-space: nowrap;
        }
      }

      .coverWrap {
        position: relative;
      }

      .pause {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 4rem;
      }

      .card_content {
        padding: 1rem 1.5rem 1rem 1.5rem;

        .title {
          font-size: 1.25rem;
          font-weight: bold;
          color: #212121;
          line-height: 2.25rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .time {
          font-size: 1rem;
          font-weight: 400;
          color: #666666;
          line-height: 2rem;
          height:2rem;
        }
      }
    }
  }
}
@media screen and(max-width:1440px) {
  .zixun .container .card .cover {
    height: 240px;
  }
  .pc .zixun .container .card .cover {
    height: auto;
  }
}
</style>
